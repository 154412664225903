<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Stock Request</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="productStockRequestInsert">
              <div class="card-body">
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Sender</label>
                      <select v-model="form.sender_id" class="form-control">
                        <option selected value="6">Warehouse General</option>
                      </select>
                      <!-- <model-list-select
                        :list="venues"
                        v-model="form.sender_id"
                        option-value="id"
                        option-text="title"
                        placeholder="Select Sender"
                        disabled
                      >
                      </model-list-select> -->
                      <small class="text-danger" v-if="errors.sender_id">{{
                        errors.sender_id[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Receiver</label>
                      <model-list-select
                        :list="venues"
                        v-model="form.receiver_id"
                        option-value="id"
                        option-text="title"
                        placeholder="Select Receiver"
                      >
                      </model-list-select>
                      <small class="text-danger" v-if="errors.receiver_id">{{
                        errors.receiver_id[0]
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Note</label>
                  <textarea
                    class="form-control"
                    style="height: 100px"
                    v-model="form.note"
                  ></textarea>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Stock</th>
                      <th>Unit</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(stock, index) in form.stocks" :key="index">
                      <td>
                        <v2-select
                          v-model="stock.product_id"
                          :options="products"
                          :reduce="(products) => products.id"
                          @input="getProductUnitParams(index)"
                          label="name"
                        >
                        </v2-select>
                        <small class="text-danger" v-if="errors.product_id">{{
                          errors.product_id[0]
                        }}</small>
                      </td>
                      <td>
                        <input
                          type="number"
                          v-model="stock.stock"
                          placeholder="Enter Stock"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <select class="form-control" v-model="stock.unit_id">
                          <option
                            v-for="unit in form.stocks[index].units"
                            :key="unit.id"
                            :value="unit.unit_id"
                          >
                            {{ unit.product_name }}/{{ unit.unit_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <div v-if="index > 0">
                          <button
                            @click="deleteFind(index)"
                            class="btn btn-danger w-100"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button @click="addFind" class="btn btn-success">
                  New Product
                </button>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import { apiGet } from '../../services/api';

export default {
  name: 'FormProductStockRequest',
  data() {
    return {
      name: 'Form Product Stock Request',
      form: {
        name: '',
        sender_id: 6,
        note: '',
        stocks: [
          {
            product_id: '',
            stock: '',
            units: [],
            price: 0,
          },
        ],
      },
      isSubmitting: false,
      errors: {},
      edit: false,
      venues: [],
      products: [],
      product_units_data: [],
    };
  },
  mounted() {
    this.getData();
    this.getVenue();
    this.getProduct();
  },
  methods: {
    getVenue() {
      apiGet('venue')
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      let id = this.$route.params.id;
      apiGet('stock_request/' + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProduct() {
      apiGet('product/all/minimal')
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductUnitParams(index) {
      axios
        .get(this.$store.state.api + 'product_unit', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.form.stocks[index].product_id,
          },
        })
        .then((result) => {
          this.form.stocks[index].units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFind: function () {
      this.form.stocks.push({
        product_id: '',
        stock: '',
        unit_id: '',
        units: [],
      });
    },
    deleteFind: function (index) {
      console.log(index);
      this.form.stocks.splice(index, 1);
    },
    productStockRequestInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + 'stock_request/store', this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success('Your Product Stock Request has been created!');
            this.$router.push({ name: 'ProductStockRequest' });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + 'stock_request/update/' + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success('Your paymentType has been updated!');
            this.$router.push({ name: 'ProductStockRequest' });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
